
import { defineComponent } from "vue";

import i18n from "../lang";
import * as requestActions from "@/store/requestActions";
export default defineComponent({
  name: "Bookingpage",
  data() {
    return {
      bookingData: null,
      room_link: '',
      time: '',
    }
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        localStorage.setItem("locale", locale);
        this.$router.go(0);
      }
    },
    async copyURL(url) {
      try {
        await navigator.clipboard.writeText(url);
        requestActions.notify({
          type: "success",
          text: i18n.global.t("welcome.copy"),
        });
      } catch($e) {

        requestActions.notify({
          type: "danger",
          text: i18n.global.t("welcome.cannotCopy"),
        });
      }
    }
  },
  mounted() {
    this.bookingData = localStorage.getItem("booking");
    this.room_link = JSON.parse(localStorage.getItem("booking")).room_link;
    this.time = new Date(parseInt(JSON.parse(localStorage.getItem("booking")).date)).toLocaleString();
    if (this.$route.query.locale) {
      this.switchLocale(this.$route.query.locale);
    }
  },
});
